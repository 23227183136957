import React, {useRef} from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import googlePlayBadge from "../images/google-play-badge.svg";
import appleAppStoreBadge from "../images/apple-app-store-badge.svg";
import fibonacciGameplay from "../images/fibonacci-gameplay.gif";
import multiplesPreview from "../images/multiples-preview.png";
import fibonacciLogo from "../images/fibonacci.svg";
import multiplesLogo from "../images/multiples.svg";
import tile8 from "../images/8.svg"
import tile233 from "../images/233.svg"
import tile12 from "../images/12.svg"
import tile18 from "../images/18.svg"
import {Link} from "gatsby";
import scrollTo from 'gatsby-plugin-smoothscroll';
import {css, keyframes} from 'styled-components'
import {Parallax, ParallaxLayer} from "@react-spring/parallax"

function IndexPage() {

    const tilesPerRow = 5
    const tilesPerCol = 4

    let parallax = useRef(null)

    const randomTiles = typeof window !== `undefined` ? Array.from(Array(tilesPerCol).keys()).map((i) => {
        return Array.from(Array(tilesPerRow).keys()).map((j) => {
            const rand = Math.random()
            let randomTile
            if (rand > 0 && rand < 0.25) {
                randomTile = tile8
            } else if (rand > 0.25 && rand < 0.5) {
                randomTile = tile18
            } else if (rand > 0.5 && rand < 0.75) {
                randomTile = tile12
            } else {
                randomTile = tile233
            }
            return (
                // <Parallax pages={1} ref={parallax} >
                //     <ParallaxLayer speed={2}>
                        <img
                        alt="number"
                        className={`block w-1/24 m-auto absolute md:hover-pause`}
                        style={{
                            top: 100 + i * window.innerHeight / (tilesPerCol + 1) + Math.random() * window.innerHeight / tilesPerCol,
                            left: j * window.innerWidth / (tilesPerRow + 1) + Math.random() * window.innerWidth / tilesPerRow,
                        }}
                        src={randomTile}
                    />
                //     </ParallaxLayer>
                // </Parallax>
            )
        })
    }) : <></>

    return (
        <Layout>
            <SEO
                keywords={[`Patn`, `Games`, `Fibonacci`, `Multiples`]}
                title="Home"
            />

            <div className="flex h-screen">
                <div className="m-auto">

                    <div>
                        <div>
                            {typeof window !== `undefined` ? randomTiles : <></>}
                        </div>
                        <div>
                        </div>

                        <section className="text-center">
                            <h1 className="text-4xl relative md:text-5xl z-10 uppercase dark:text-white">
                                ENGAGING<b> NUMBER GAMES</b>
                            </h1>
                        </section>
                    </div>

                    <div>
                        {/*<button*/}
                        {/*    onClick={() => scrollTo('#fibonacci')}*/}
                        {/*    onKeyDown={() => scrollTo('#fibonacci')}*/}
                        {/*    className="block m-auto cursor-pointer animate-bounce-delay-1/4 md:animate-none">*/}
                        {/*    <span className="text-5xl uppercase dark:text-white"*/}
                        {/*          role="img" aria-label="Point down">*/}
                        {/*        👇🏿*/}
                        {/*    </span>*/}
                        {/*</button>*/}
                    </div>
                    <div className="py-10"/>
                </div>
            </div>
            <div id="fibonacci"
                 className="h-screen flex flex-col justify-center">
                <div className="m-auto">
                    <div
                        className="m-auto md:inline-block md:align-middle w-2/3 text-center md:text-left">
                        <div>
                            <img src={fibonacciLogo} alt="Fibonacci"/>
                        </div>
                        <div>
                            <br/>
                            <p> Swipe
                                <span role="img"
                                      aria-label="Left arrow"> ⬅️ </span>
                                <span role="img"
                                      aria-label="Right arrow"> ➡️ </span>
                                <span role="img"
                                      aria-label="Up arrow"> ⬆️ </span>
                                <span role="img"
                                      aria-label="Down arrow"> ⬇️ </span>
                                ️ to merge any number with the one
                                that
                                came before it. Try not to run out of
                                space!
                            </p>
                            <br/>
                            <p>
                                <a
                                    href="https://youtu.be/Cruk0vSajHs">
                                    Click to watch the number pattern
                                <span role="img"
                                                  aria-label="Play"> ▶️ </span>
                                    ️</a>
                            </p>
                            <br/>
                            <p><strong>Download for free today</strong></p>
                            <br/>
                            <div className="block space-x-8">
                                <a className="md:inline-block"
                                   href="https://play.google.com/store/apps/details?id=games.patn.fibonacci">
                                    <img src={googlePlayBadge}
                                         className="mx-auto w-48 md:h-16"
                                         alt="Download in Google Play"/>
                                </a>
                                <a className="md:inline-block"
                                   href="https://apps.apple.com/us/app/id1517534132">
                                    <img src={appleAppStoreBadge}
                                         className="mx-auto w-48 md:h-16"
                                         alt="Download in Apple's App Store"/>
                                </a>
                                <a className="m-auto" href="https://www.producthunt.com/posts/fibonacci?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-fibonacci">
                                    <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=269673&theme=light"
                                         alt="Fibonacci - A clever maths pattern turned into a fun puzzle game | Product Hunt"
                                         className="m-auto md:m-0 w-60 h-16"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="m-auto md:inline-block md:align-middle w-1/3 p-5 md:p-0">
                        <img src={fibonacciGameplay}
                             className="m-auto w-7/12 object-contain"
                             alt="Fibonacci gameplay animation"/>
                    </div>
                </div>
            </div>
            <div id="multiples" className="h-screen flex flex-col justify-center">
                <div className="m-auto">
                    <div className="align-middle w-1/3 hidden md:inline-block">
                        <img src={multiplesPreview}
                             className="w-7/12"
                             alt="Fibonacci gameplay animation"/>
                    </div>
                    <div
                        className="m-auto md:inline-block md:align-middle w-2/3 text-center md:text-left">
                        <div><img src={multiplesLogo} alt="Multiples preview"/>
                        </div>
                        <br/>
                        <div>
                            <p>Want to casually improve
                                your multiplication skills? Multiples is a
                                new type of maths game that helps you to master mental arithmetic.
                                Quickly recall sums like <strong>5 x 7</strong> and <strong>13 x
                                    14</strong>.
                            </p>
                            <br/>
                            <p>
                                Numbers rotate each day:
                                <strong> 2x</strong>,
                                <strong> 3x</strong>,
                                <strong> 4x</strong>, up to
                                <strong> 19x</strong>
                            </p>
                            <br/>
                            <p><strong>
                                Coming soon to Android and iOS!
                            </strong>
                            </p>
                        </div>
                    </div>
                    <div
                        className="md:hidden m-auto md:inline-block md:align-middle w-1/3 p-5 md:p-0">
                        <img src={multiplesPreview}
                             className="m-auto w-7/12 object-contain"
                             alt="Fibonacci gameplay animation"/>
                    </div>
                </div>
            </div>

        </Layout>
    );
}

export default IndexPage;
